<template>
  <div class="wrapper">
    <div class="right">
      <div class="background" :style="'background-image: url(' + require(`@/assets/img/background.png`) + ')'">
      </div>
    </div>
    <div class="left">
      <slot></slot>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
  </template>
  
  <style lang="scss" scoped>
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    background-color: var(--color-primary-background);
    height: fit-content;
    min-height: 100vh;
    .footer {
      position: absolute;
      bottom: 33px;
      @media screen and (max-height: 600px){
        position: initial;
        bottom: 0;
        padding: 33px;
      }
    }
    .left {
      width: 50%;
      min-height: 100vh;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
  
      @media(max-width: 1084px) {
        width: 100%;
      }
    }
    .right {
      width: 50%;
      min-height: inherit;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
  
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media(max-width: 1084px) {
        display: none;
      }
      .background{
        width: 100%;
        height: 100%;
        min-height: inherit;
        background-size: cover;
      }
    }
  }
  </style>
  